import * as amplitude from '@amplitude/analytics-browser';

import {Book, Prompt, Style} from './constants';

declare global {
  interface Window {
    LemonSqueezy: {
      Setup: (_: {
        eventHandler: (_: {
          event: 'Checkout.Success';
          data: {order: {data: {id: string; attributes: {user_email: string}}}};
        }) => void;
      }) => void;
      Url: {Open: (checkoutUrl: string) => void; Close: () => void};
    };
  }
}

export const IS_DEV = process.env.NODE_ENV !== 'production';

export const CONFIG = IS_DEV
  ? {
      API_SERVER: `http://${window.location.host}:8080`,
      AMPLITUDE_KEY: '633882e83a947605cbd7659aab435258',
      LEMON_EBOOK:
        'https://omida.lemonsqueezy.com/checkout/buy/7cc1a0ae-66b2-453a-97af-eb55c324cad4?embed=1&logo=0&desc=0&dark=1',
    }
  : {
      API_SERVER: 'https://cutebooks.uc.r.appspot.com',
      AMPLITUDE_KEY: '633882e83a947605cbd7659aab435258',
      LEMON_EBOOK:
        'https://omida.lemonsqueezy.com/checkout/buy/f29871b8-7772-45ea-a13a-0b3c7af7b312?embed=1&logo=0&desc=0&dark=1',
    };

export const createBook = async (args: {
  id: string;
  email: string;
  prompt?: Prompt;
  style?: Style;
}) =>
  fetch(CONFIG.API_SERVER + '/createBook', {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(args),
  }).then(async r => {
    const resp = await r.json();
    if (!r.ok || !('content' in resp) || !('prompt' in resp) || !('style' in resp)) {
      throw resp.error || resp;
    }
    return resp as Book;
  });

export const parseBook = (poem: string, author: string) => {
  const content = poem
    .replace(/"(.*)"/g, '“$1”')
    .replaceAll("'", '’')
    .replaceAll('  ', ' ')
    .replace(/\n +/g, '\n')
    .split('\n\n')
    .map(p =>
      p
        .split('\n')
        .map(l => l.trim())
        .filter(Boolean)
    );

  const title = content[0][0].trim().replace(/["“”]/g, '').trim();
  const frontCover = [title, 'Created by', author];
  const backCover = ['Cute Books'];
  const contentPages = content.slice(1).map(page =>
    page.map(line => {
      const firstHalf = line.split(' ');
      const secondHalf: string[] = [];
      while (firstHalf.join(' ').length > secondHalf.join(' ').length) {
        secondHalf.unshift(firstHalf.pop()!);
      }
      firstHalf.push(secondHalf.shift()!);
      return firstHalf.join(' ') + '\n' + secondHalf.join(' ');
    })
  );

  const printPages = PRINT_PAGES_BY_LENGTH[contentPages.length] ?? null;

  return {title, pages: [frontCover, ...contentPages, backCover], printPages};
};

export const logAmpEvent = (event: string, properties?: {[key: string]: string}) =>
  amplitude.track({event_type: event, event_properties: properties});

const PRINT_PAGES_BY_LENGTH: {[k: number]: [number, number][]} = {
  10: [
    [11, 0],
    [8, 1],
    [2, 7],
    [4, 5],
    [6, 3],
    [-1, 9],
    [10, -1],
  ],
  9: [
    [10, 0],
    [-1, 9],
    [8, 1],
    [2, 7],
    [6, 3],
    [4, 5],
  ],
  8: [
    [9, 0],
    [8, 1],
    [2, 7],
    [6, 3],
    [4, 5],
  ],
  7: [
    [8, 0],
    [-1, 1],
    [2, 7],
    [6, 3],
    [4, 5],
  ],
  6: [
    [7, 0],
    [1, 6],
    [5, 2],
    [3, 4],
  ],
  5: [
    [6, 0],
    [-1, 5],
    [4, 1],
    [2, 3],
  ],
  4: [
    [5, 0],
    [4, 1],
    [2, 3],
  ],
};
