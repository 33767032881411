import './input.css';

import React from 'react';

import {ZERO_WIDTH} from '../utils/constants';

interface Props
  extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLSpanElement>, HTMLSpanElement> {
  value: string;
  onValueChange: (v: string) => void;
  maxLength?: number;
  autoFocus?: boolean;
}

export const FancyInput = React.forwardRef<HTMLSpanElement, Props>((props, ref) => {
  const prevValue = React.useRef<string | null>(null);

  const setValue = (value: string, focus?: boolean) => {
    if (value === prevValue.current) return;
    prevValue.current = value;
    const newValue = value.substring(0, props.maxLength) || ZERO_WIDTH;
    let caretPosition = window.getSelection()?.focusOffset ?? -1;
    if (caretPosition === -1 || caretPosition > newValue.length) {
      caretPosition = newValue.length;
    }
    if (typeof ref !== 'function' && ref?.current) {
      ref.current.textContent = newValue;
      if (focus) {
        const selection = window.getSelection();
        if (selection && newValue) {
          const range = document.createRange();
          range.setStart(ref.current.childNodes[0], caretPosition);
          range.collapse(true);
          selection.removeAllRanges();
          selection.addRange(range);
          ref.current.focus();
        }
      }
    }
    props.onValueChange(newValue);
  };

  React.useEffect(() => {
    setValue(props.value, props.autoFocus);
  }, [props.value]);

  return (
    <span
      ref={ref}
      contentEditable
      className={'input ' + (props.className ?? '')}
      onKeyDown={e => e.key === 'Enter' && e.preventDefault()}
      onInput={e => setValue(e.currentTarget.textContent ?? '', true)}
      onAnimationEnd={e => e.currentTarget.classList.remove('animated')}
      autoCapitalize="off"
    />
  );
});
