import blueBorder from './styles/blue-border.jpg';
import blueCircle from './styles/blue-circle.png';
import greenBorder from './styles/green-border.jpg';
import greenCircle from './styles/green-circle.png';
import orangeBorder from './styles/orange-border.jpg';
import orangeCircle from './styles/orange-circle.png';
import pinkBorder from './styles/pink-border.jpg';
import pinkCircle from './styles/pink-circle.png';
import purpleBorder from './styles/purple-border.jpg';
import purpleCircle from './styles/purple-circle.png';

export const ZERO_WIDTH = '​';

export const samplePoem = `Luna's Lunar Lullaby\n\nOnce lived a little girl, Luna by name,\nHer voice was as sweet as a sugar cane.\nEvery day and every night,\nShe serenaded the moon so bright.\n\nLuna dreamed of songs in space,\nOf singing in that starry place.\n“Someday,” she whispered in the soft night glow,\n“I’ll sing where the planets float and flow.”\n\nIn a rocket, she climbed high,\nWaving at Earth as she zipped by.\nDressed in her best astronaut gear,\nLuna was brave and showed no fear.\n\nIn zero gravity, Luna gave a twirl,\nHer voice floated out, a lyrical pearl.\nShe sang to Saturn, Venus, and Mars,\nHer voice echoed among the stars!\n\nComets danced to Luna’s song,\nPlanets pirouetted along.\nThe Milky Way shone extra bright,\nAs Luna sang with all her might.\n\nEvery start twinkled to her tune,\nSmiling down at the singing moon.\nEven the sun could only beam,\nCaught in the spell of Luna’s dream.\n\nThen Luna knew her mission's end,\nBack to Earth, she must descend.\nBut with her music, soft and clear,\nShe'd left a song for the stars to hear.\n\nNow every night, look up and see,\nLuna's space symphony.\nListen as the winds gently sway,\nHer song is in the stars, they say.\n`;

export interface Prompt {
  subject: string;
  name: string;
  description: string;
  topic: string;
  author: string;
}

export interface Book {
  prompt: Prompt;
  style: Style;
  content: string;
}

export const promptConfig: {
  [k in keyof Prompt]: {placeholders: string[]; maxLength: number};
} = {
  subject: {placeholders: ['boy', 'girl', 'cat'], maxLength: 20},
  name: {placeholders: ['Quinn', 'Luna', 'Felix'], maxLength: 20},
  description: {
    placeholders: ['dinosaurs', 'to sing', 'superheroes'],
    maxLength: 50,
  },
  topic: {
    placeholders: ['has fun at the dentist', 'goes to space', 'joins a soccer team'],
    maxLength: 50,
  },
  author: {placeholders: ['Mommy and Daddy', 'Uncle Jake', 'Avery'], maxLength: 20},
};

export const styleConfig = {
  purple: {
    border: purpleBorder,
    circle: purpleCircle,
    color: '#C46CE0',
    fontFamily: 'Rowdies',
    fontWeights: [400, 400],
  },
  pink: {
    border: pinkBorder,
    circle: pinkCircle,
    color: '#FF1DA6',
    fontFamily: 'Museo Moderno',
    fontWeights: [700, 900],
  },
  blue: {
    border: blueBorder,
    circle: blueCircle,
    color: '#4EA2E9',
    fontFamily: 'Poller One',
    fontWeights: [400, 400],
  },
  green: {
    border: greenBorder,
    circle: greenCircle,
    color: '#72D566',
    fontFamily: 'Azeret Mono',
    fontWeights: [700, 800],
  },
  orange: {
    border: orangeBorder,
    circle: orangeCircle,
    color: '#FFDA65',
    fontFamily: 'Grandstander',
    fontWeights: [700, 800],
  },
};

export type Style = keyof typeof styleConfig;
