import {logAmpEvent} from '../utils/api';

interface Props {
  downloadDigital?: () => void;
  downloadPrint?: (() => void) | null;
}

const downloadSVG = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="currentColor"
    viewBox="0 0 16 16"
  >
    <path d="M8.5 6.5a.5.5 0 0 0-1 0v3.793L6.354 9.146a.5.5 0 1 0-.708.708l2 2a.5.5 0 0 0 .708 0l2-2a.5.5 0 0 0-.708-.708L8.5 10.293V6.5z" />
    <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z" />
  </svg>
);

export const DownloadButton = ({downloadDigital, downloadPrint}: Props) => {
  const isLoading = !downloadDigital || downloadPrint === undefined;

  return isLoading ? (
    <>
      <div style={{color: '#fff', fontSize: '24px'}}>
        Preparing download...
        <div className="loading-bar" style={{marginTop: '12px', height: '4px'}}>
          <div className="loading-bar-value"></div>
        </div>
      </div>
    </>
  ) : (
    <div>
      <button
        className="button primary icon"
        onClick={() => {
          downloadDigital();
          logAmpEvent('Downloaded book', {type: 'digital'});
        }}
      >
        {downloadSVG} Digital
      </button>
      {!!downloadPrint && (
        <button
          className="button primary icon blue"
          onClick={() => {
            downloadPrint();
            logAmpEvent('Downloaded book', {type: 'print'});
          }}
        >
          {downloadSVG} Print
        </button>
      )}
    </div>
  );
};
