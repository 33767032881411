import React from 'react';

export const useRouter = () => {
  const replaceRoute = (route: Route) => {
    window.history.replaceState({}, '', route);
  };

  const getRoute = (): Route => {
    let path = window.location.pathname;
    if (isValidRoute(path)) {
      return path;
    }

    if (path.endsWith('/')) {
      path = path.substring(-1, path.length + 1);
      if (isValidRoute(path)) {
        replaceRoute(path);
        return path;
      }
    }

    replaceRoute('/');
    return '/';
  };

  const [route, setRoute] = React.useState(getRoute());
  React.useEffect(() => {
    const onRouteChange = () => setRoute(getRoute());
    window.addEventListener('popstate', onRouteChange);
    return () => {
      window.removeEventListener('popstate', onRouteChange);
    };
  }, []);

  const set = (r: Route) => {
    replaceRoute(r);
    setRoute(r);
  };

  return {route, set};
};

export type Route = `/${
  | ''
  | 'terms'
  | 'privacy'
  | 'support'
  | 'sample'
  | 'retrieve'
  | 'open-source'}`;

export const isValidRoute = (path: string): path is Route => {
  const segments = path.split('/');
  switch (segments.length) {
    case 0:
    case 1:
      return false;
    case 2:
      return (
        segments[0] === '' &&
        ['', 'terms', 'privacy', 'support', 'sample', 'retrieve', 'open-source'].includes(
          segments[1]
        )
      );
  }

  return false;
};
