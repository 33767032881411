import './support.css';

import React from 'react';

import {logAmpEvent} from '../utils/api';

type Config = {title: string; items: [string, string | React.ReactNode][]}[];

const supportConfig: Config = [
  {
    title: 'About',
    items: [
      [
        `What is Cute Books?`,
        `Cute Books is an easy-to-use online service that employs artificial intelligence to instantly create fully customized children's e-books that are silly, fun, and sure to delight. Simply provide a few details to inspire the story, choose from one of five modern book designs, and get a completely unique and adorable e-book for only $1.99.`,
      ],
      [
        `How does it work?`,
        `Cute Books works by harnessing the power of OpenAI to generate one-of-a-kind children’s stories based on the details you provide, and turns these stories into cute, colorful (and often comical) e-books.`,
      ],
      [
        `Is each book completely customized?`,
        `Yes. Unlike most other “custom” children’s books, which use generic templates with a few fill-in-the-blank items, each of our e-books is a unique creation written with OpenAI. In other words, the details you provide are not plugged into formulaic, conventional, pre-written stories: they inspire completely new stories that are always amusing, sometimes absurd, but never ordinary.`,
      ],
    ],
  },
  {
    title: 'Product',
    items: [
      [
        `How long is each book?`,
        `The length of each e-book may vary depending on the particular information provided to inspire it. With that said, Cute Books is focused on creating short, rhyming stories for children, and a typical book has about eight pages, with one stanza per page.`,
      ],
      [
        `Are there pictures?`,
        <>
          {`While our e-books do not include pictures or illustrations, we offer five modern design options that add color and charm to the story. You can view the five designs `}
          <a href="/sample">here</a>
          {`. These appealing and fun designs combined with the captivating, rhyming text of the stories will more than capture the interest and attention of readers of all ages!`}
        </>,
      ],
      [
        `What is the format of the e-book?`,
        `Our e-books are available for download in PDF format, enabling them to be easily viewed on a wide variety of devices (including computers, smartphones, tablets, and e-readers). You will receive two clearly-labeled PDFs for each book: an online-friendly e-book, and a print-ready version.`,
      ],
      [
        `Can I order a hard copy of the book?`,
        `Cute Books currently offers e-books for digital download only. However, in additional to the online-friendly e-book, you will also receive a print-ready PDF that you can use to create a physical copy using standard letter-sized paper.`,
      ],
      [
        `Can I see the book before I buy it?`,
        <>
          {`While you are unable to see your specific book before you buy it, you may view a sample story and the five book design options `}
          <a href="/sample">here</a>
          {` before moving forward.`}
        </>,
      ],
      [
        `Can I choose a different book design after I buy it?`,
        <>
          {`Currently, Cute Books does not offer the option to change the book design after creation. We recommend reviewing the available styles `}
          <a href="/sample">here</a>
          {` prior to making the purchase.`}
        </>,
      ],
    ],
  },
  {
    title: 'Payment',
    items: [
      [
        `Why does it cost $1.99?`,
        `At only $1.99, Cute Books is one of the most affordable custom children’s books options on the market. Not only do we make it fun and virtually effortless to create a fully custom children’s story, but we beautifully format it into an aesthetically-pleasing e-book available for instant download. With Cute Books, in just minutes — if not seconds — you have a cute, colorful, completely unique children’s book that kids of all ages will love.`,
      ],
      [
        `Is there a discount if I want to get multiple books?`,
        `Currently, Cute Books offers its e-books at a flat rate of $1.99 per book, regardless of how many you buy. While we may consider offering a discount for multiple books in the future, for now we trust that the affordability of the service still permits users to create multiple books.`,
      ],
      [
        `Can I get a refund?`,
        `Unfortunately, we are not currently able to offer refunds. While we are confident that you’ll be more than happy with your book, we understand that artificial intelligence is an evolving tool and can have varied results. If you have any problems or concerns, we encourage you to contact us at support@cutebooks.app, and we will do our absolute best to resolve them.`,
      ],
    ],
  },
  {
    title: 'Help',
    items: [
      [
        `How do I download my book?`,
        `Immediately after your book has been created, you can download it to your device using the “Download” buttons in the top right corner. We recommend downloading both the digital and the print-friendly versions.`,
      ],
      [
        `How do I find my book again?`,
        <>
          {`To download your e-book again any time after its initial generation, simply:`}
          <ul>
            <li>{`Copy the license key listed on the receipt that was sent to your email from Omida Software.`}</li>
            <li>{`Click the purple “Retrieve e-book” button at the bottom of the email, which will take you to cutebooks.app/retrieve.`}</li>
            <li>{`Enter your email address and license key to access your e-book.`}</li>
          </ul>
        </>,
      ],
      [
        `How do I print my book?`,
        `The print-ready PDF (whose name begins with “PRINT”) has two side-by-side pages per sheet, and is designed to perfectly fit on letter-sized paper. Simply print as you normally would, and then fold each sheet in half to create your own physical copy.`,
      ],
      [
        `I’m not happy with my book, can I get a refund?`,
        `Unfortunately, we are not currently able to offer refunds. While we are confident that you’ll be more than happy with your book, we understand that artificial intelligence is an evolving tool and can have varied results. If you have any problems or concerns, we encourage you to contact us at support@cutebooks.app, and we will do our absolute best to resolve them.`,
      ],
    ],
  },
  {
    title: 'Contact',
    items: [
      [
        `How do I get in touch?`,
        <>
          {`Whether you have feedback, suggestions, or would simply like to learn more about Cute Books, we'd love to hear from you at hello@cutebooks.app.`}
          <br />
          <br />
          {`If you're having issues with your order, please reach out to support@cutebooks.app so we can help you out as soon as possible!`}
        </>,
      ],
    ],
  },
];

export const Support = () => (
  <>
    {supportConfig.map((section, sIdx) => (
      <div className="support-section" key={sIdx}>
        <h2 className="support-title">{section.title}</h2>
        {section.items.map((item, iIdx) => (
          <Accordion item={item} key={iIdx} />
        ))}
      </div>
    ))}
  </>
);

const Accordion = ({item}: {item: [string, string | React.ReactNode]}) => {
  const [isOpen, setIsOpen] = React.useState(false);
  return (
    <div
      className={'accordion' + (isOpen ? ' open' : '')}
      onClick={() => {
        setIsOpen(o => !o);
        if (!isOpen) logAmpEvent('Viewed FAQ item', {question: item[0]});
      }}
    >
      <div className="accordion-title">{item[0]}</div>
      {isOpen && <div className="accordion-content">{item[1]}</div>}
    </div>
  );
};
