import './book.css';

import {toCanvas} from 'html-to-image';
import jsPDF from 'jspdf';
import React from 'react';

import logo from '../logo.svg';
import {parseBook} from '../utils/api';
import {Style, styleConfig} from '../utils/constants';

const BookPage: React.FC<{
  pages: string[][];
  idx: number;
  style: Style;
  print?: boolean;
  children?: React.ReactNode;
}> = ({pages, idx, style, print, children}) => {
  const pageType =
    idx === -1
      ? 'blank'
      : idx === 0
      ? 'front-cover'
      : idx === pages.length - 1
      ? 'back-cover'
      : 'regular-page';
  const pageSide = idx % 2 ? 'right' : 'left';
  const pageNumber = [-1, 0, pages.length - 1].includes(idx) ? null : idx;

  const classes = ['book', pageType, pageSide, print ? 'book-print' : ''];

  return (
    <div
      className={classes.join(' ')}
      style={{
        ['--fontFamily' as any]: `'${styleConfig[style].fontFamily}'`,
        ['--fontWeightRegular' as any]: styleConfig[style].fontWeights[0],
        ['--fontWeightBold' as any]: styleConfig[style].fontWeights[1],
      }}
    >
      <img src={styleConfig[style].border} className="book-background" />
      <div className="book-main">
        {pageType === 'blank' ? null : pageType === 'back-cover' ? (
          <>
            <img src={logo} className="cover-logo" />
            <div className="book-line">{pages[idx][0]}</div>
          </>
        ) : (
          pages[idx].map((line, lineIdx) => (
            <div key={lineIdx} className="book-line">
              {line.split('\n').map((l, lIdx) => (
                <React.Fragment key={lIdx}>
                  {l}
                  {lIdx === 0 && <br />}
                </React.Fragment>
              ))}
            </div>
          ))
        )}
        <div key={-1} className="book-number">
          {pageNumber}
        </div>
      </div>
      {children}
    </div>
  );
};

interface Props {
  poem: string;
  author: string;
  style: Style;
  setDownloadDigital?: (fn: () => void) => void;
  setDownloadPrint?: (fn: (() => void) | null) => void;
}

export const Book = (props: Props) => {
  const book = parseBook(props.poem, props.author);
  const [pageIdx, setPageIdx] = React.useState(0);

  return (
    <>
      <BookPage pages={book.pages} idx={pageIdx} style={props.style}>
        <button
          className="button book-button left"
          onClick={() => pageIdx > 0 && setPageIdx(pageIdx - 1)}
        >
          ‹
        </button>
        <button
          className="button book-button right"
          onClick={() => pageIdx < book.pages.length - 1 && setPageIdx(pageIdx + 1)}
        >
          ›
        </button>
      </BookPage>

      <PrintSurface {...props} />
    </>
  );
};

const PrintSurface = (props: Props) => {
  const book = parseBook(props.poem, props.author);
  const [isPrint, setIsPrint] = React.useState(false);
  const [pageIdx, setPageIdx] = React.useState(0);

  const eDocRef = React.useRef(new jsPDF({orientation: 'p', unit: 'in', format: [5, 7.5]}));
  const pDocRef = React.useRef(new jsPDF({orientation: 'l', unit: 'in', format: [11, 8.5]}));

  React.useEffect(() => {
    const onLoad = async () => {
      if (!isPrint && props.setDownloadDigital) {
        const page = document.querySelector('.book-print') as HTMLElement;
        const [isFirst, isLast] = [!pageIdx, pageIdx === book.pages.length - 1];

        const canvasWidth = 1000 / window.devicePixelRatio;
        const canvasHeight = 1500 / window.devicePixelRatio;

        if (isFirst || isLast) {
          // Do pointless work to allow rendering on iOS. setTimeout doesn't work.
          await toCanvas(page, {canvasWidth, canvasHeight});
          await toCanvas(page, {canvasWidth, canvasHeight});
          await toCanvas(page, {canvasWidth, canvasHeight});
        }

        const canvas = await toCanvas(page, {canvasWidth, canvasHeight});

        if (!isFirst) eDocRef.current.addPage();
        eDocRef.current.addImage(canvas, 'JPEG', 0, 0, 5, 7.5);

        if (isLast) {
          props.setDownloadDigital(() => {
            eDocRef.current.save(book.title + ' (Digital).pdf');
          });
          setIsPrint(true);
          setPageIdx(0);
        } else {
          setPageIdx(pageIdx + 1);
        }
      }

      if (isPrint && props.setDownloadPrint) {
        const page = document.querySelector('.book-print-double') as HTMLElement;
        const [isFirst, isLast] = [!pageIdx, pageIdx === book.printPages.length - 1];

        const canvasWidth = 2000 / window.devicePixelRatio;
        const canvasHeight = 1500 / window.devicePixelRatio;

        const canvas = await toCanvas(page, {canvasWidth, canvasHeight});

        if (!isFirst) pDocRef.current.addPage();
        pDocRef.current.addImage(canvas, 'JPEG', 0.5, 0.5, 10, 7.5);

        if (isLast) {
          props.setDownloadPrint(() => {
            pDocRef.current.save(book.title + ' (Print).pdf');
          });
        } else {
          setPageIdx(pageIdx + 1);
        }
      }
    };

    onLoad();
  }, [pageIdx]);

  return (
    <div className="book-print-surface">
      {isPrint ? (
        <div className="book-print-double">
          {book.printPages[pageIdx].map(pIdx => (
            <BookPage key={pIdx} pages={book.pages} idx={pIdx} style={props.style} />
          ))}
        </div>
      ) : (
        <BookPage pages={book.pages} idx={pageIdx} style={props.style} print />
      )}
    </div>
  );
};
