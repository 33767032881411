import './app.css';

import React from 'react';

import * as amplitude from '@amplitude/analytics-browser';

import {Book} from './components/book';
import {DownloadButton} from './components/download';
import {FancyInput} from './components/input';
import {OpenSource} from './components/opensource';
import {Privacy} from './components/privacy';
import {Support} from './components/support';
import {Terms} from './components/terms';
import logo from './logo.svg';
import {CONFIG, createBook, IS_DEV, logAmpEvent} from './utils/api';
import {Prompt, promptConfig, samplePoem, Style, styleConfig, ZERO_WIDTH} from './utils/constants';
import {useRouter} from './utils/router';

type PreviewPage = {
  type: 'preview';
  prompt: Prompt;
  poem: string;
  isSample: boolean;
  downloadDigital?: () => void;
  downloadPrint?: (() => void) | null;
};

type MainPage =
  | {type: 'main'}
  | {type: 'loading'}
  | {type: 'error'; error: React.ReactNode}
  | PreviewPage;

type Page =
  | MainPage
  | {type: 'privacy'}
  | {type: 'terms'}
  | {type: 'open-source'}
  | {type: 'support'}
  | {type: 'retrieve'}
  | PreviewPage;

const closeIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="currentColor"
    viewBox="0 0 16 16"
  >
    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
  </svg>
);

export const App = () => {
  const createBookHandler = React.useRef(
    (args: Parameters<typeof createBook>[0], isCreateNew?: boolean) => {}
  );

  const router = useRouter();
  const [_page, _setPage] = React.useState<MainPage>({type: 'main'});
  const page: Page =
    router.route === '/terms'
      ? {type: 'terms'}
      : router.route === '/privacy'
      ? {type: 'privacy'}
      : router.route === '/open-source'
      ? {type: 'open-source'}
      : router.route === '/support'
      ? {type: 'support'}
      : router.route === '/retrieve'
      ? {type: 'retrieve'}
      : router.route === '/sample'
      ? {
          type: 'preview',
          prompt: {
            subject: promptConfig.subject.placeholders[1],
            name: promptConfig.name.placeholders[1],
            description: promptConfig.description.placeholders[1],
            topic: promptConfig.topic.placeholders[1],
            author: promptConfig.author.placeholders[0],
          },
          poem: samplePoem,
          isSample: true,
        }
      : _page;
  const setPage = (p: MainPage | ((newP: MainPage) => MainPage)) => {
    router.set('/');
    _setPage(p);
  };

  const [style, setStyle] = React.useState<Style>('purple');
  const [prompt, setPrompt] = React.useState<Prompt>({
    subject: '',
    name: '',
    description: '',
    topic: '',
    author: '',
  });
  const phRefs = {
    subject: React.useRef<HTMLSpanElement | null>(null),
    name: React.useRef<HTMLSpanElement | null>(null),
    description: React.useRef<HTMLSpanElement | null>(null),
    topic: React.useRef<HTMLSpanElement | null>(null),
    author: React.useRef<HTMLSpanElement | null>(null),
  };

  const [retrieveInfo, setRetrieveInfo] = React.useState({id: '', email: ''});

  createBookHandler.current = async (args, isCreateNew) => {
    setPage({type: 'loading'});
    try {
      const book = await createBook({...args, ...(isCreateNew ? {prompt, style} : {})});
      setStyle(book.style);
      setPrompt(book.prompt);
      setPage({
        type: 'preview',
        prompt: book.prompt,
        poem: book.content,
        isSample: false,
      });
    } catch (e) {
      switch (e) {
        case 'invalid_payment':
          setPage({
            type: 'error',
            error: `We were unable to retrieve your transaction details — please verify your payment information.`,
          });
          break;
        case 'generation_error':
        default:
          setPage({
            type: 'error',
            error: (
              <>
                {`Something went wrong — please `}
                <a onClick={() => createBookHandler.current(args, isCreateNew)}>click here</a>
                {` to try again.`}
              </>
            ),
          });
          break;
      }
    }
  };

  React.useEffect(() => {
    !IS_DEV &&
      amplitude.init(CONFIG.AMPLITUDE_KEY, {
        serverUrl: CONFIG.API_SERVER + '/amp',
        identityStorage: 'none',
      });

    const lemonInterval = setInterval(() => {
      if (window.LemonSqueezy) {
        clearInterval(lemonInterval);
        window.LemonSqueezy.Setup({
          eventHandler: ({event, data}) => {
            if (event === 'Checkout.Success') {
              window.addEventListener('hashchange', () => {
                if (window.location.hash === '#payment-success') {
                  window.LemonSqueezy.Url.Close();
                  window.history.replaceState('', document.title, window.location.pathname);
                }
              });
              createBookHandler.current(
                {
                  id: data.order.data.id,
                  email: data.order.data.attributes.user_email.toLowerCase(),
                },
                true
              );
              logAmpEvent(`Finished payment`);
            }
          },
        });
      }
    }, 100);

    const fields = Object.keys(prompt);
    const delay = fields.length * 2000;
    const phCount = promptConfig.name.placeholders.length;

    const intervals: NodeJS.Timer[] = [];
    const timeouts: NodeJS.Timeout[] = [];

    const changePH = (field: keyof Prompt) => {
      const el = phRefs[field].current;
      if (el) {
        const currentPH = parseInt(el.dataset.phIdx ?? '-1', 10);
        const nextPH = (currentPH + 1) % phCount;
        el.dataset.phIdx = nextPH.toString();
        el.setAttribute('placeholder', promptConfig[field].placeholders[nextPH]);
        el.classList.add('animated');
      }
    };

    fields.forEach((_field, idx) => {
      const field = _field as keyof Prompt;
      changePH(field); // Set initial value
      timeouts.push(
        setTimeout(() => {
          changePH(field);
          intervals.push(setInterval(() => changePH(field), delay));
        }, (idx + 1) * 2000)
      );
    });

    return () => {
      timeouts.forEach(clearTimeout);
      intervals.forEach(clearInterval);
    };
  }, []);

  React.useEffect(() => {
    const pageType = page.type === 'preview' ? (page.isSample ? 'sample' : 'book') : page.type;
    const extra =
      page.type === 'error'
        ? {error: typeof page.error === 'string' ? page.error : 'Try again'}
        : undefined;
    logAmpEvent(`Viewed ${pageType}`, extra);

    if (pageType === 'retrieve') {
      const query = new URL(window.location.href).searchParams;
      const info = {id: query.get('key') ?? '', email: query.get('email') ?? ''};
      if (info.id && info.email) {
        setRetrieveInfo(info);
        createBookHandler.current(info);
        window.history.replaceState('', document.title, window.location.pathname);
      }
    }
  }, [page.type]);

  const promptInput = (field: keyof Prompt) => (
    <FancyInput
      ref={phRefs[field]}
      value={prompt[field]}
      onValueChange={value => setPrompt(p => ({...p, [field]: value}))}
      maxLength={promptConfig[field].maxLength}
      autoFocus={field === 'subject'}
      className={prompt[field] === ZERO_WIDTH ? 'empty' : ''}
    />
  );

  const stylePicker = (
    <div className="prompt-content prompt-styles">
      {Object.keys(styleConfig).map(s => (
        <div
          key={s}
          className={style === s ? 'selected' : ''}
          onClick={() => {
            setStyle(s as Style);
            logAmpEvent('Changed style', {
              style,
              source: page.type === 'preview' ? 'sample' : 'home',
            });
          }}
        >
          <img src={styleConfig[s as Style].circle} />
        </div>
      ))}
    </div>
  );

  const modalContent = (() => {
    switch (page.type) {
      case 'loading':
        return (
          <div className="modal-center loading-container">
            <div>Preparing your (super cute) book...</div>
            <div className="loading-text-small">Hang tight! This should only take a minute.</div>
            <div className="loading-bar">
              <div className="loading-bar-value"></div>
            </div>
          </div>
        );
      case 'error':
      case 'retrieve':
        return (
          <div className="modal-center">
            <div className="error-container">
              {page.type === 'error' ? (
                <>
                  <div className="error-title">Oops!</div>
                  {page.error}
                </>
              ) : (
                <>
                  <div className="error-title">Retrieve your e‑book</div>
                  <input
                    placeholder="Email"
                    value={retrieveInfo.email}
                    onChange={e =>
                      setRetrieveInfo({...retrieveInfo, email: e.currentTarget.value.toLowerCase()})
                    }
                    maxLength={50}
                  />
                  <input
                    placeholder="License key"
                    value={retrieveInfo.id}
                    onChange={e => setRetrieveInfo({...retrieveInfo, id: e.currentTarget.value})}
                    maxLength={50}
                  />
                  <button
                    className="button secondary white"
                    style={{width: '100%'}}
                    onClick={() => createBookHandler.current(retrieveInfo)}
                  >
                    Retrieve
                  </button>
                </>
              )}
              <button
                className="button secondary small white error-close"
                onClick={() => setPage({type: 'main'})}
              >
                {closeIcon}
              </button>
            </div>
          </div>
        );
      case 'privacy':
        return (
          <>
            <div className="modal-header">
              <button className="button secondary white" onClick={() => setPage({type: 'main'})}>
                Close
              </button>
            </div>
            <div className="legal-page">
              <Privacy />
            </div>
          </>
        );
      case 'terms':
        return (
          <>
            <div className="modal-header">
              <button className="button secondary white" onClick={() => setPage({type: 'main'})}>
                Close
              </button>
            </div>
            <div className="legal-page">
              <Terms />
            </div>
          </>
        );
      case 'open-source':
        return (
          <>
            <div className="modal-header">
              <button className="button secondary white" onClick={() => setPage({type: 'main'})}>
                Close
              </button>
            </div>
            <div className="legal-page">
              <OpenSource />
            </div>
          </>
        );
      case 'support':
        return (
          <>
            <div className="modal-header">
              <button className="button secondary white" onClick={() => setPage({type: 'main'})}>
                Close
              </button>
            </div>
            <div className="legal-page">
              <Support />
            </div>
          </>
        );
      case 'preview':
        return (
          <>
            <div className="modal-header">
              <button className="button secondary white" onClick={() => setPage({type: 'main'})}>
                Close
              </button>
              {!page.isSample && (
                <DownloadButton
                  downloadDigital={page.downloadDigital}
                  downloadPrint={page.downloadPrint}
                />
              )}
            </div>
            <div className="preview-body">
              <div className="preview-prompt">
                <span className="prompt-bold">Create a cute children’s e‑book</span> about a{' '}
                <span className="prompt-bold" style={{color: styleConfig[style].color}}>
                  {page.prompt.subject}
                </span>{' '}
                named{' '}
                <span className="prompt-bold" style={{color: styleConfig[style].color}}>
                  {page.prompt.name}
                </span>
                , who loves{' '}
                <span className="prompt-bold" style={{color: styleConfig[style].color}}>
                  {page.prompt.description}
                </span>
                , and{' '}
                <span className="prompt-bold" style={{color: styleConfig[style].color}}>
                  {page.prompt.topic}
                </span>
                .
                <br />
                <br />
                Created by{' '}
                <span className="prompt-bold" style={{color: styleConfig[style].color}}>
                  {page.prompt.author}
                </span>
                {!!page.isSample && stylePicker}
              </div>
              <Book
                poem={page.poem}
                author={page.prompt.author}
                style={style}
                {...(page.isSample
                  ? {}
                  : {
                      setDownloadDigital: fn =>
                        setPage(p => (p.type === 'preview' ? {...p, downloadDigital: fn} : p)),
                      setDownloadPrint: fn =>
                        setPage(p => (p.type === 'preview' ? {...p, downloadPrint: fn} : p)),
                    })}
              />
            </div>
          </>
        );
      default:
        return null;
    }
  })();

  React.useEffect(() => {
    if (modalContent) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [modalContent]);

  return (
    <div className="app-root">
      <div className="app-header">
        <div className="app-header-brand">
          <img src={logo} className="app-header-logo" />
          <div>Cute Books</div>
        </div>
        <div style={{flex: 1}} />
        {[
          {text: 'View a sample', style: 'not-mobile'},
          {text: 'Sample', style: 'mobile'},
        ].map(({text, style}) => (
          <button
            key={style}
            className={`button primary ${style}`}
            onClick={() => router.set('/sample')}
          >
            {text}
          </button>
        ))}
        <button className="button secondary" onClick={() => router.set('/support')}>
          FAQ
        </button>
      </div>
      <div className="app-content">
        <div className="prompt">
          <div className="prompt-card">
            <div className="prompt-title">Content</div>
            <div className="prompt-content">
              <span className="prompt-bold">Create a cute children’s e‑book</span> about a{' '}
              {promptInput('subject')} named {promptInput('name')}, who loves{' '}
              {promptInput('description')}, and {promptInput('topic')}.
            </div>
          </div>
          <div className="prompt-card">
            <div className="prompt-title">Style</div>
            {stylePicker}
          </div>
          <div className="prompt-card">
            <div className="prompt-title">Author</div>
            <div className="prompt-content">Created by {promptInput('author')}</div>
          </div>
          <div className="prompt-buttons">
            <button
              className="button primary large"
              onClick={() => {
                if (Object.values(prompt).some(v => !v.replace(ZERO_WIDTH, '').trim())) {
                  setPage({type: 'error', error: `It looks like a few fields are still blank.`});
                } else {
                  window.LemonSqueezy.Url.Open(CONFIG.LEMON_EBOOK);
                  logAmpEvent(`Started payment`);
                }
              }}
            >
              Download now for $1.99
            </button>
          </div>
        </div>
      </div>
      <div className="app-footer">
        <button className="button link" onClick={() => router.set('/privacy')}>
          Privacy
        </button>
        <button className="button link" onClick={() => router.set('/terms')}>
          Terms
        </button>
        <button className="button link" onClick={() => router.set('/open-source')}>
          Atrributions
        </button>
        <button className="button link" onClick={() => router.set('/support')}>
          Support
        </button>
      </div>
      {!!modalContent && <div className="modal">{modalContent}</div>}
    </div>
  );
};
